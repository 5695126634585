export interface Theme {
  name: string;
  properties: any;
}

// blue: #296ec6;
// darker blue: #1662c7;
// light blue: '#d5e7ff'
export const defaultTheme: Theme = {
  name: 'defaultTheme',
  properties: {
    '--main-color': '#296ec6',
    '--card-border': '#1662c7',
    '--button-color': '#296ec6',
    '--button-hover-color': '#1662c7',
    '--tag-bg-color': '#d5e7ff',
  },
};

// dark gray: #2a3439;
// dark red: #a32428;
// light gray: #f7f1f2
export const redTheme: Theme = {
  name: 'redTheme',
  properties: {
    '--main-color': '#a32428',
    '--card-border': '#2a3439',
    '--button-color': '#2a3439',
    '--button-hover-color': '#a32428',
    '--tag-bg-color': '#f7f1f2',
  },
};
