import { Injectable } from '@angular/core';
import { defaultTheme, redTheme, Theme } from '../_models/themeInterfaces';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private active!: Theme;
  // private availableThemes: Theme[] = [defaultTheme, redTheme];

  constructor() {}

  setDefaultTheme() {
    this.setActiveTheme(defaultTheme);
  }

  setRedTheme(): void {
    this.setActiveTheme(redTheme);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }
}
